import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import {
  AppProvider,
  chakraTheme,
  PopUpMessageProvider,
  PracticeAccessProvider,
} from '@hb/shared-frontend'
import { MainWindowProvider } from '@hb/shared-frontend/contexts/MainWindowContext'
import { ToastContainer } from '@hb/shared-frontend/toast'
// import 'focus-visible/dist/focus-visible'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { APP_NAME } from './constants'
// import {CookieBanner} from './1'

const emotionCache = createCache({
  key: 'emotion-css-cache',
  prepend: true, // ensures styles are prepended to the <head>, instead of appended
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <MainWindowProvider>
      <ColorModeScript type="cookie" initialColorMode="light" />
      <CacheProvider value={emotionCache}>
        <ChakraProvider
          theme={chakraTheme}
          portalZIndex={3}
          toastOptions={{ defaultOptions: { position: 'top' } }}>
          <Router>
            <CookiesProvider>
              <PopUpMessageProvider>
                <PracticeAccessProvider>
                  <AppProvider appName={APP_NAME}>
                    <App />
                  </AppProvider>
                </PracticeAccessProvider>
              </PopUpMessageProvider>
            </CookiesProvider>
          </Router>
        </ChakraProvider>
      </CacheProvider>
      <ToastContainer />
    </MainWindowProvider>,
  )
}
