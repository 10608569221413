import { AppName, ProfileItem } from '@hb/shared'
import { SubTab, SubTabGroup } from '../../../shared/Nav/types'
import { PregnancyTabName, ProfileVersion, UserProfileTabName } from '../types'

type PregnancyTab = SubTab<PregnancyTabName>
const assessmentTab: PregnancyTab = {
  path: 'assessment',
  name: 'Assessment',
}
const insuranceInfoTab: PregnancyTab = {
  path: 'insurance',
  name: 'Insurance Info',
}
const signOnInfoTab: PregnancyTab = {
  path: 'sign-on',
  name: 'Sign On Info',
}
const paymentsTab: PregnancyTab = {
  path: 'payments',
  name: 'Payments',
}
const plansTab: PregnancyTab = {
  path: 'plans',
  name: 'Insurance Plans',
}
const authorizationsTab: PregnancyTab = {
  path: 'authorizations',
  name: 'Authorizations',
}

export const patientSummaryTab: ProfileTab = {
  path: 'patient',
  name: 'Patient',
}
export const pregnancyTab: ProfileTab = {
  path: 'pregnancy',
  name: 'Pregnancy',
}
const consentFormTab: ProfileTab = {
  path: 'consent-forms',
  name: 'Consent Forms',
}
const patientPregnancyTab: ProfileTab = {
  path: 'patient',
  name: 'Patient / Pregnancy',
}

const claimsTab: ProfileTab = {
  path: 'claims',
  name: 'Claims / Charting',
}
const filesTab: ProfileTab = { path: 'files', name: 'Files' }
const visitsTab: ProfileTab = { path: 'visits', name: 'Visits' }

const getPregnancyTabs = (appName: AppName): Array<SubTab | SubTabGroup> => {
  const tabs: Array<SubTab | SubTabGroup> = []
  tabs.push(appName === 'app' ? assessmentTab : insuranceInfoTab)
  // tabs.push(appName === 'app' ? signOnInfoTab : summaryTab)
  if (appName === 'app') {
    tabs.push(signOnInfoTab)
    tabs.push(authorizationsTab)
  }
  // if (appName === 'providers-app') tabs.push(paymentsTab)
  tabs.push(paymentsTab)
  tabs.push(plansTab)
  return tabs
}

const getV1PregnancySubTabs = (appName: AppName): Array<SubTab> => {
  const tabs: Array<SubTab> = []
  tabs.push(appName === 'app' ? assessmentTab : insuranceInfoTab)
  tabs.push(appName === 'app' ? signOnInfoTab : assessmentTab)
  tabs.push(plansTab)
  return tabs
}

const getV1PregnancyTabs = (appName: AppName): Array<SubTab | SubTabGroup> => {
  const tabs: Array<SubTab | SubTabGroup> = []
  tabs.push(appName === 'app' ? patientSummaryTab : patientPregnancyTab)
  tabs.push({
    name: 'Pregnancy',
    path: 'pregnancy',
    children: getV1PregnancySubTabs(appName),
  })
  tabs.push(insuranceInfoTab)
  tabs.push(paymentsTab)
  if (appName === 'app') {
    tabs.push(authorizationsTab)
    tabs.push(claimsTab)
  }
  tabs.push(filesTab)
  tabs.push(visitsTab)
  tabs.push(consentFormTab)
  return tabs
}

/*
 const tabs = useMemo<Array<string>>(() => {
    const tabs: Array<string> = []
    tabs.push(appName === 'app' ? 'Patient' : 'Patient / Pregnancy')
    if (selectedAssessment) tabs.push(appName === 'app' ? 'Assessment' : 'Billing / Insurance')
    if (appName === 'app' && selectedAssessment) {
      tabs.push('Payments')
      tabs.push('Authorizations')
      tabs.push('Claims / Charting')
    }
    if (selectedAssessment) tabs.push('Files')
    if (selectedAssessment) tabs.push('Visits')
    return tabs
  }, [appName, selectedAssessment])
*/

type ProfileTab = SubTab<UserProfileTabName>
type ProfileTabGroup<SubPath extends string = string> = SubTabGroup<UserProfileTabName, SubPath>
export const patientTab: ProfileTabGroup = {
  name: 'Patient',
  path: 'patient',
  children: [patientSummaryTab, pregnancyTab, insuranceInfoTab, consentFormTab, visitsTab],
}
export const getPregnancyTab = (
  appName: AppName,
  profileVersion: ProfileVersion,
): ProfileTabGroup => ({
  path: 'pregnancy',
  // name: appName === 'app' ? 'Assessment' : 'Billing / Insurance',
  name: 'Pregnancy',
  children: profileVersion === 'v1' ? getV1PregnancyTabs(appName) : getPregnancyTabs(appName),
})

const v1PatientTabs: Array<ProfileTab> = [patientSummaryTab, pregnancyTab, consentFormTab]

const getV1BaseItemTabs = (appName: AppName, baseItem: ProfileItem | null) => {
  if (!baseItem) return v1PatientTabs
  switch (baseItem.type) {
    case 'claim':
    case 'pregnancy':
      return getV1PregnancyTabs(appName)
    default:
      return v1PatientTabs
  }
}

// const v2PatientTab: ProfileTabGroup = {
//   name: 'Patient',
//   path: 'patient',
//   children: [
//     {
//       name: 'Patient',
//       path: 'patient',
//     },
//     {
//       name: 'Pregnancy',

//     }
//   ]
// }
export const getV2PregnancyTabs = (appName: AppName, pregnancyId?: string): Array<SubTab> => {
  const tabs: Array<SubTab> = []
  tabs.push(patientTab)
  const entity = pregnancyId ? { type: 'pregnancy', id: pregnancyId } : null
  if (appName === 'app') tabs.push(assessmentTab)
  // tabs.push(appName === 'app' ? assessmentTab : insuranceInfoTab)
  tabs.push(signOnInfoTab)
  tabs.push(plansTab)
  tabs.push(filesTab)
  return entity ? tabs.map(tab => ({ ...tab, entity })) : tabs
}

export const getV2PregnancyTab = (appName: AppName): ProfileTabGroup => ({
  name: 'Pregnancy',
  path: 'pregnancy',
  children: getV2PregnancyTabs(appName),
})

const getV2BaseItemTabs = (
  appName: AppName,
  selectedItem: ProfileItem | null,
): Array<SubTab | SubTabGroup> => {
  if (!selectedItem) return [patientTab]
  switch (selectedItem.type) {
    case 'claim':
    case 'pregnancy':
      return getV2PregnancyTabs(appName, selectedItem.id)
    default:
      return [patientTab]
  }
}

export const getBaseItemTabs = (
  appName: AppName,
  selectedItem: ProfileItem | null,
  profileVersion: ProfileVersion,
): Array<SubTab | SubTabGroup> =>
  profileVersion === 'v1'
    ? getV1BaseItemTabs(appName, selectedItem)
    : getV2BaseItemTabs(appName, selectedItem)

// export const getTabsV2 = (
//   appName: AppName,
//   selectedItem: ProfileItem | null,
//   // assessmentId: string | null | undefined,
// ): Array<SubTab | SubTabGroup> => {
//   const tabs: Array<SubTab | SubTabGroup> = []
//   tabs.push(appName === 'app' ? patientTab : patientPregnancyTab)
//   if (selectedItem?.type === 'pregnancy') tabs.push(getPregnancyTab(appName))
//   if (appName === 'app' && selectedItem?.type === 'pregnancy') {
//     tabs.push(paymentsTab)
//     tabs.push(authorizationsTab)
//     tabs.push(claimsTab)
//   }
//   if (selectedItem?.type === 'pregnancy') tabs.push(filesTab)
//   tabs.push(visitsTab)
//   tabs.push(consentFormTab)
//   return tabs
// }

// export const getTabsV1 = (
//   appName: AppName,
//   selectedItem: ProfileItem | null,
// ): Array<SubTab | SubTabGroup> => {
//   const tabs: Array<SubTab | SubTabGroup> = []
//   tabs.push(patientTab)
//   const isPregnancy = selectedItem?.type === 'pregnancy'
//   if (isPregnancy) tabs.push(getPregnancyTab(appName))
//   tabs.push(plansTab)
//   if (appName === 'app' && isPregnancy) {
//     tabs.push(paymentsTab)
//     tabs.push(authorizationsTab)
//     tabs.push(claimsTab)
//   }
//   if (isPregnancy) tabs.push(filesTab)
//   if (isPregnancy) tabs.push(visitsTab)
//   tabs.push(consentFormTab)
//   return tabs
// }
