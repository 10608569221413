import { CloseIcon } from '@chakra-ui/icons'
import { Button, Center, Collapse, Divider, Flex, Text } from '@chakra-ui/react'
import {
  FieldMapValue,
  kebabCaseToTitleCase,
  newPatientConfirmField,
  newPatientReviewField,
  ProfileType,
  profileTypes,
  USER_ROLES,
  UserRoleItem,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import { collection, CollectionReference, getDocs, query, Query, where } from 'firebase/firestore'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { inviteUser } from '../../backend'
import { db } from '../../backend/db'
import { useApp, usePopUpMessage } from '../../contexts'
import { useSelectedPractice } from '../../hooks'
import { useAppModals } from '../../store'
import { GrowFadeBox } from '../Animation/GrowFadeBox'
import { ActionButton, SolidActionButton } from '../Buttons'
import { SimpleForm } from '../forms'
import { Loading } from '../Loading'
import { UserPreview } from '../Users/UserPreview'
import { DefaultModal } from './DefaultModal'

const fetchMatches = async (formData: FieldMapValue) => {
  const { fname, lname, email, dob } = formData

  if (!fname) throw new Error('First name is required')
  if (!lname) throw new Error('Last name is required')
  const userRolesCollection = collection(db, USER_ROLES) as CollectionReference<UserRoleItem>
  const processedFname = (fname ?? '').trim().toLowerCase()
  const processedLname = (lname ?? '').trim().toLowerCase()
  const nameQuery = query(
    userRolesCollection,
    where('fnameLower', '==', processedFname),
    where('lnameLower', '==', processedLname),
  )

  const queries: Query<UserRoleItem>[] = [nameQuery]
  if (dob) {
    queries.push(
      query(
        userRolesCollection,
        where('lnameLower', '==', processedLname),
        where('dob', '==', formData.dob),
      ),
    )
  }
  if (email) {
    queries.push(query(userRolesCollection, where('email', '==', email)))
  }

  const matches: Record<string, UserRoleItem> = {}
  const promises = queries.map(q =>
    getDocs(q).then(snap => {
      snap.forEach(doc => {
        matches[doc.id] = { ...doc.data(), id: doc.id }
      })
    }),
  )
  await Promise.all(promises)
  return Object.values(matches)
}

const ProfileTypeSelect = ({
  profileType,
  onChange,
}: {
  profileType: ProfileType | null
  onChange: (updated: ProfileType) => void
}) => (
  <Flex align="center" pt={1} gap={2} flexFlow="column" w="100%">
    <Text opacity={0.8} lineHeight={1} fontFamily="Hero-New">
      What is the nature of the initial care?
    </Text>
    <Flex flexFlow="row" gap={2} px={2} w="100%">
      {profileTypes.map(value => (
        <Button
          flex={1}
          key={value}
          variant="unstyled"
          display="flex"
          alignItems="center"
          color={profileType === value ? 'white' : 'gray.700'}
          textShadow={profileType === value ? '1px 1px 2px #00000066' : 'none'}
          border="1px solid"
          borderColor={profileType === value ? 'green.500' : 'gray.300'}
          bg={profileType === value ? 'green.500' : 'white'}
          size="sm"
          isActive={profileType === value}
          onClick={() => {
            onChange(value)
          }}>
          <Text position="relative" bottom="1px">
            {kebabCaseToTitleCase(value)}
          </Text>
        </Button>
      ))}
    </Flex>
    <AnimatePresence mode="popLayout">
      {profileType === 'office-visit' ? (
        <GrowFadeBox
          overflow="hidden"
          borderRadius={3}
          boxShadow="md"
          maxW="270px"
          px={3}
          py={1.5}
          bg="white"
          key="office-visit">
          <Text
            fontWeight={500}
            fontFamily="Hero-New"
            textAlign="center"
            fontSize="sm"
            opacity={0.8}>
            Patient profile will display office visits by default.
          </Text>
        </GrowFadeBox>
      ) : null}
      {
        // This is a new feature that was added in the new version of the app
        profileType === 'pregnancy' ? (
          <GrowFadeBox
            overflow="hidden"
            borderRadius={3}
            px={3}
            maxW="270px"
            boxShadow="md"
            py={1.5}
            bg="white"
            key="pregnancy">
            <Text
              fontWeight={500}
              fontFamily="Hero-New"
              textAlign="center"
              color="gray.700"
              fontSize="sm"
              opacity={0.8}>
              Patient profile will display pregnancy info by default.
            </Text>
          </GrowFadeBox>
        ) : null
      }
    </AnimatePresence>
  </Flex>
)

const InvitePatientReview = ({
  formData,
  onBack,
  onComplete,
}: {
  formData: FieldMapValue | null
  onBack: () => void
  onComplete: () => void
}) => {
  const { showMessage } = usePopUpMessage()
  const [loadingMatches, setLoadingMatches] = useState(false)
  const [creatingNew, setCreatingNew] = useState(false)
  const [profileType, setProfileType] = useState<ProfileType | null>(null)
  const [matches, setMatches] = useState<UserRoleItem[] | null>(null)

  const navigate = useNavigate()
  useEffect(() => {
    if (!formData) return
    setLoadingMatches(true)
    fetchMatches(formData)
      .then(matches => {
        setMatches(matches)
      })
      .catch(e => {
        showMessage({
          text: 'Error checking existing patients',
          subText: e.message,
          type: 'error',
        })
      })
      .finally(() => {
        setLoadingMatches(false)
      })
  }, [formData, showMessage])

  const handleBack = useCallback(() => {
    setMatches(null)
    setCreatingNew(false)
    onBack()
  }, [onBack])

  const { appName } = useApp()
  const { id: selectedPracticeId } = useSelectedPractice()

  const handleConfirmInvite = useCallback(
    async (data: FieldMapValue) => {
      if (!profileType) return { [FORM_ERROR]: 'Profile type is required' }
      const submitted = { ...formData, ...data }
      try {
        const { patientId, pregnancyId } = await inviteUser(
          appName,
          submitted,
          profileType,
          appName === 'providers-app' ? selectedPracticeId : null,
        )
        showMessage({
          type: 'success',
          text: submitted?.email ? 'Patient invited' : 'Profile created',
        })
        const path = pregnancyId ? `/pregnancies/${pregnancyId}` : `/patients/${patientId}`
        navigate(appName === 'app' ? `/admin${path}` : path)
        onComplete()
        return undefined
      } catch (e: any) {
        showMessage({
          text: 'Error inviting patient',
          subText: e.message,
          type: 'error',
        })
        return { [FORM_ERROR]: e.message ?? 'Error inviting patient' }
      }
    },
    [formData, appName, selectedPracticeId, showMessage, navigate, profileType, onComplete],
  )

  const initValue = useMemo(
    () => ({
      email: formData?.email ?? '',
      dob: formData?.dob ?? undefined,
    }),
    [formData],
  )

  return (
    <Flex flexFlow="column" w="100%">
      <Collapse style={{ width: '100%' }} in={!!loadingMatches && !creatingNew}>
        <Center w="100%">
          <Loading text="Loading potential matches" />
        </Center>
      </Collapse>
      <Collapse style={{ width: '100%' }} in={!loadingMatches && !creatingNew}>
        <Flex flexFlow="column" w="100%">
          <Flex borderBottom="1px solid #cdcdcd" py={1.5} px={2} align="center" width="100%">
            <Text opacity={0.8} lineHeight={1} fontWeight={500} fontFamily="Hero-New">
              Matches
            </Text>
          </Flex>
          <Flex
            bg="#e4e4e4"
            maxH="350px"
            overflowY="auto"
            gap={2}
            py={1}
            px={2}
            flexFlow="column"
            width="100%">
            {matches?.length ? (
              matches.map(m => <UserPreview key={m.id} goTo userId={m.id} />)
            ) : (
              <Text fontStyle="italic">No matching patients found</Text>
            )}
          </Flex>
          <Flex
            borderTop="1px solid #cdcdcd"
            gap={2}
            bg="gray.50"
            justify="flex-end"
            p={2}
            w="100%"
            align="center">
            <ActionButton size="sm" onClick={handleBack}>
              Back
            </ActionButton>
            <SolidActionButton
              size="sm"
              onClick={() => {
                setCreatingNew(true)
              }}>
              {formData?.email ? 'Invite New Patient' : 'Create New Profile'}
            </SolidActionButton>
          </Flex>
        </Flex>
      </Collapse>
      <Collapse style={{ width: '100%' }} in={creatingNew}>
        <Flex px={1} pt={3} flexFlow="column" w="100%">
          <Flex flexFlow="column" px={2} width="100%">
            <Text lineHeight={1} fontFamily="Hero-New" fontWeight={500} opacity={0.85}>
              Create New Profile for {formData?.fname ?? ''}
              {formData?.middleName ? ` ${formData.middleName}` : ''} {formData?.lname ?? ''}
            </Text>
          </Flex>
          <Divider my={1} />
          <ProfileTypeSelect profileType={profileType} onChange={setProfileType} />
          <Divider mb={1} mt={2} />
          <SimpleForm
            canSubmitClean
            value={initValue}
            boxProps={{
              p: 0,
              bg: 'transparent',
              boxShadow: 'none',
            }}
            field={newPatientConfirmField}
            onSubmit={handleConfirmInvite}
          />
        </Flex>
      </Collapse>
    </Flex>
  )
}

const InvitePatientContent = ({ onClose }: { onClose: () => void }) => {
  const [formData, setFormData] = useState<FieldMapValue | null>(null)

  return (
    <Flex bg="gray.100" flexFlow="column" w="100%">
      <Flex borderBottom="1px solid #cdcdcd" bg="gray.50" px={2} py={1} align="center" width="100%">
        <Text fontFamily="Hero-New" fontSize="lg" fontWeight={400} opacity={0.8}>
          Invite Patient
        </Text>
        <Button
          size="xs"
          bg="whiteAlpha.800"
          borderRadius="full"
          variant="outline"
          color="gray.700"
          gap={1}
          ml="auto"
          onClick={onClose}>
          <CloseIcon opacity={0.8} w={2} h={2} />
          <Text>CLOSE</Text>
        </Button>
      </Flex>
      <Flex flexFlow="column" w="100%">
        <Collapse in={!formData} style={{ width: '100%' }}>
          <SimpleForm
            boxProps={{
              p: 0,
              bg: 'transparent',
            }}
            field={newPatientReviewField}
            onSubmit={async data => {
              setFormData(data)
              return undefined
            }}
          />
        </Collapse>
        <Collapse in={!!formData} style={{ width: '100%' }}>
          <InvitePatientReview
            onComplete={onClose}
            onBack={() => {
              setFormData(null)
            }}
            formData={formData}
          />
        </Collapse>
      </Flex>
    </Flex>
  )
}
export const InvitePatientModal = () => {
  const { openId, close } = useAppModals(s => s.invitePatient)
  return (
    <DefaultModal
      isOpen={openId === 'root'}
      onClose={close}
      overlayHeader
      closeDisabled
      render={() => <InvitePatientContent onClose={close} />}
    />
  )
}
