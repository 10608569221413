import { DateField } from '@hb/shared'
import React, { forwardRef, useContext, useMemo } from 'react'
import { ScreenContext } from '../../../../contexts'
import { useSiteSettings } from '../../../../store/settings'
import { InputElement, InputProps } from '../../../../types/fields'
import { NativeDateInput } from './NativeDateInput'
import { PopoverDateInput } from './PopoverDateInput'

export const DateInput: InputElement<DateField> = forwardRef<
  { focus: () => void },
  InputProps<DateField>
>((props, ref) => {
  const { isMobile } = useContext(ScreenContext)
  const nativeDateInputOverride = useSiteSettings(s => s.nativeDateInputOverride)
  const displayNative = useMemo(
    () => nativeDateInputOverride ?? (props.field.nativeInput && !isMobile),
    [props.field.nativeInput, isMobile, nativeDateInputOverride],
  )
  return displayNative ? (
    <NativeDateInput {...props} ref={ref} />
  ) : (
    <PopoverDateInput {...props} ref={ref} />
  )
})
