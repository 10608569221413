import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  CircularProgress,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors, getPracticeFilePath, getResizedStoragePath, Practice } from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { reportPracticeFileUpload } from '../../../backend/functions'
import { uploadFile } from '../../../backend/storage'
import { useApp } from '../../../contexts/AppContext'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useFile, useIsPracticeSuperAdmin, useRecentlyUploaded } from '../../../hooks'
import breastfeedingIcon from '../../../icons/breastfeeding.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import uploadIcon from '../../../icons/upload.svg'
import { LoadingImage } from '../../shared/LoadingImage'
import { SimpleImageUpload } from '../../shared/SimpleImageUpload'

const PracticeLogoPopoverContent = ({
  practice,
  loading,
  onCancel,
  practiceId,
}: {
  practice: Practice | null
  onCancel?: () => void
  loading: boolean
  practiceId: string
}) => {
  const { logoFile } = practice ?? {}
  const { uploadedOn } = logoFile ?? {}
  const storagePath = useMemo(() => getPracticeFilePath(practiceId, 'logo'), [practiceId])
  const downloadPath = useMemo(
    () => (practice?.logoFile ? getResizedStoragePath(storagePath, practice.logoFile.type) : null),
    [storagePath, practice],
  )
  const recentlyUploaded = useRecentlyUploaded(uploadedOn)

  const fileArgs = useMemo(
    () => ({
      path: downloadPath ?? '',
      pathLoading: recentlyUploaded,
      noFetch: !uploadedOn,
    }),
    [downloadPath, recentlyUploaded, uploadedOn],
  )

  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)

  const onUpload = useCallback(
    async (uploaded: File, onUploadProgress: (v: number) => void) => {
      if (!storagePath) return
      try {
        uploadFile(storagePath, uploaded, onUploadProgress)
        await reportPracticeFileUpload({
          appName,
          contentType: uploaded.type,
          fileId: 'logo',
          practiceId,
        })
      } catch (err: any) {
        processResponse({ error: err?.message ?? 'Error uploading file' })
        throw err
      }
    },
    [storagePath, processResponse, appName, practiceId],
  )

  const { url, loading: fileLoading } = useFile(fileArgs)
  return (
    <Flex w="100%" direction="column">
      <Flex px={1} align="center" gap={1}>
        <InfoIcon w={3} color="gray.500" />
        <Text color="gray.500">The practice logo is visible to all patients</Text>
      </Flex>
      <SimpleImageUpload
        src={url}
        label="Practice Logo"
        onCancel={onCancel}
        vertical
        loading={loading || fileLoading}
        recentlyUploaded={recentlyUploaded}
        onUpload={onUpload}
      />
    </Flex>
  )
}

export const PracticeLogoView = ({
  practice,
  practiceId,
  loading,
}: {
  practice: Practice | null
  practiceId: string
  loading: boolean
}) => {
  const isPracticeSuperAdmin = useIsPracticeSuperAdmin(practiceId)
  // TODO: either remove this or use it
  const [isUploading] = useState(false)

  const downloadPath = useMemo(
    () =>
      practice?.logoFile
        ? getResizedStoragePath(getPracticeFilePath(practiceId, 'logo'), practice.logoFile.type)
        : null,
    [practiceId, practice],
  )
  const { url, loading: fileLoading } = useFile({
    path: downloadPath ?? '',
    value: practice?.logoFile,
  })
  const [hovered, setHovered] = useState(false)
  const overlay = isPracticeSuperAdmin ? (
    <>
      <Center
        w="100%"
        h="100%"
        position="absolute"
        transition="opacity 333ms ease"
        pointerEvents="none"
        bg="blackAlpha.400"
        opacity={hovered ? 0.8 : 0}
        left={0}
        top={0}>
        {isUploading ? (
          <HStack>
            <CircularProgress color={colors.green.hex} size={4} />
            <Text
              fontWeight={600}
              fontFamily="Hero-New"
              color="white"
              textAlign="center"
              textShadow="1px 1px 3px #00000077">
              Uploading...
            </Text>
          </HStack>
        ) : (
          <VStack p={2} spacing={1}>
            <SvgIcon
              filter="brightness(4) drop-shadow(1px 1px 3px #00000077)"
              src={uploadIcon}
              w={8}
            />
            <Text
              fontWeight={600}
              fontFamily="Hero-New"
              color="white"
              lineHeight={1.1}
              fontSize="lg"
              textAlign="center"
              textShadow="1px 1px 3px #00000077">
              Click to Upload
            </Text>
          </VStack>
        )}
      </Center>
    </>
  ) : null
  const body = (
    <LoadingImage
      loading={fileLoading}
      cursor={isPracticeSuperAdmin ? 'pointer' : 'default'}
      filter="drop-shadow(1px 1px 3px #00000077)"
      height="120px"
      src={url ?? breastfeedingIcon}
    />
  )
  const content = (
    <Flex
      bg={`${colors.green.hex}66`}
      borderRadius={10}
      overflow="hidden"
      position="relative"
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      boxShadow="inset 1px 1px 4px #00000066">
      {body}
      {overlay}
    </Flex>
  )

  return overlay ? (
    <Popover closeOnEsc={false} closeOnBlur={false} isLazy placement="right" strategy="fixed">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box>{content}</Box>
          </PopoverTrigger>
          <PopoverContent
            filter="drop-shadow(0 0 6px #00000077)"
            bg="gray.100"
            border="none"
            p={2}
            borderRadius={10}
            w="auto">
            <PracticeLogoPopoverContent
              practice={practice}
              onCancel={onClose}
              loading={loading}
              practiceId={practiceId}
            />
            <PopoverArrow bg="gray.100" />
          </PopoverContent>
        </>
      )}
    </Popover>
  ) : (
    content
  )
}
