import { WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Assessment, colors, getAssessmentName } from '@hb/shared'

import React, { useContext, useMemo, useState } from 'react'
import { NameEditModal } from '../..'
import { AppContext, ScreenContext } from '../../../../../contexts'
import { ProfileContext } from '../../../../../contexts/ProfileContext'
import { useCreateAssessment, useUpdateDoc } from '../../../../../hooks'
import { AssessmentIcon } from '../../../../../icons'
import { ProfileRedFlagPopover } from '../../../../DataView'
import { Expandable } from '../../../../Expandable'
import { DefaultModal } from '../../../../Modals'
import { PatientMidwifeInfo } from '../../../../Practices'
import { AddAssessmentAlert } from '../../Assessments/AddAssessmentAlert'
import { ReportDeliveryPopover } from '../../Assessments/ReportDeliveryPopover'
import { UploadFilePopover } from '../../Files/UploadFilePopover'
import { PartnerPopover } from '../../Patient/PartnerPopover'
import { PronounsView } from '../../Patient/PronounsView'
import { AddPaymentPopover } from '../../Payments/AddPaymentPopover'
import { ProfileHeaderAlerts } from '../../ProfileHeaderAlerts'
import { ProfileSortColorPopover } from '../../SortColorPopover'
import { ProfileUrgentPopover } from '../../UrgentPopover'

const MidwifeRedFlagPopover = ({ redFlaggedReason }: { redFlaggedReason?: string }) => (
  <Popover trigger="hover">
    <PopoverTrigger>
      <WarningIcon
        ml={3}
        w={4}
        h={4}
        color={redFlaggedReason ? 'red.600' : 'gray.400'}
        cursor="pointer"
      />
    </PopoverTrigger>
    <PopoverContent w="auto">
      <PopoverArrow />
      {redFlaggedReason ? (
        <PopoverHeader fontWeight={600} color="red.600">
          RED FLAG
        </PopoverHeader>
      ) : null}
      <PopoverBody w="auto" minW="0" maxW="400px" p={0}>
        <Text
          whiteSpace="pre-wrap"
          p={2}
          fontSize="sm"
          color={redFlaggedReason ? 'red.600' : 'gray.600'}>
          {redFlaggedReason ?? 'Not red flagged'}
        </Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

const AssessmentSelect = () => {
  const {
    goTo,
    selectedAssessment: { populated: selectedAssessment },
    assessments,
  } = useContext(ProfileContext)

  const { isMobile } = useContext(ScreenContext)
  const { addLoading, confirmingNewAssessment, createAssessment, setConfirmingNewAssessment } =
    useCreateAssessment()

  const { archivedAssessments, nonArchivedAssessments } = useMemo(() => {
    const arr = Object.entries(assessments ?? {})
    const archived: { id: string; data: Assessment }[] = []
    const nonArchived: { id: string; data: Assessment }[] = []
    arr.forEach(([id, data]) => {
      if (data.archivedOn) {
        archived.push({ id, data })
      } else {
        nonArchived.push({ id, data })
      }
    })
    return {
      archivedAssessments: archived,
      nonArchivedAssessments: nonArchived,
    }
  }, [assessments])

  return (
    <>
      <Menu gutter={2} isLazy strategy="fixed" placement="bottom-start">
        <MenuButton bg="white" shadow="md" minW="0" isLoading={addLoading} px={2} as={Button}>
          <Flex px={1.5} align="center">
            {selectedAssessment ? <AssessmentIcon /> : null}
            <Text
              lineHeight={1}
              py={1}
              fontSize="sm"
              fontWeight={500}
              fontFamily="Hero-New"
              maxW={isMobile ? '100%' : '175px'}
              isTruncated>
              {selectedAssessment ? getAssessmentName(selectedAssessment) : 'Select a Pregnancy'}
            </Text>
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList bg="gray.100" maxW="380px" minW="300px" py={0} overflowY="auto" maxH="350px">
            {nonArchivedAssessments?.length
              ? nonArchivedAssessments.map(({ id, data }, i) => (
                  <MenuItem
                    key={`${data.createdOn || i}`}
                    onClick={() => goTo({ entity: { id, type: 'pregnancy' }, tab: null })}
                    _hover={{ bg: 'rgb(245,245,245)' }}
                    bg="white"
                    borderBottom="1px solid #cdcdcd"
                    px={3}
                    py={2}>
                    <AssessmentIcon mr={2} />
                    {getAssessmentName(data)}
                  </MenuItem>
                ))
              : null}
            {archivedAssessments?.length ? (
              <Expandable
                header={() => (
                  <Text px={2} py={1} color="red.600" fontSize="sm" fontWeight={600}>
                    ARCHIVED
                  </Text>
                )}>
                {archivedAssessments.map(({ id, data }, i) => (
                  <MenuItem
                    key={`${data.createdOn || i}`}
                    borderTop="1px solid #cdcdcd"
                    onClick={() => goTo({ entity: { id, type: 'pregnancy' }, tab: null })}
                    bg="white"
                    _hover={{ bg: 'rgb(245,245,245)' }}
                    px={3}
                    py={2}>
                    <AssessmentIcon />
                    {getAssessmentName(data)}
                  </MenuItem>
                ))}
              </Expandable>
            ) : null}
            <MenuItem
              borderTop="1px solid #cdcdcd"
              bg={colors.green.hex}
              _hover={{ bg: 'green.400' }}
              color="white"
              fontFamily="Comfortaa"
              fontWeight={600}
              textShadow="1px 1px 3px rgba(0,0,0,0.5)"
              fontSize="sm"
              onClick={() => setConfirmingNewAssessment(true)}>
              + NEW PREGNANCY
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <AddAssessmentAlert
        onConfirm={createAssessment}
        isOpen={confirmingNewAssessment}
        onCancel={() => setConfirmingNewAssessment(false)}
      />
    </>
  )
}

export const PatientHeaderRow = ({ width }: { width: number }) => {
  const {
    selectedAssessment: { practiceRef, snippetRef, populated: selectedAssessment },
    user,
    patientRef,
  } = useContext(ProfileContext)
  const { appName } = useContext(AppContext)
  const { nickname, fname, lname, pronouns } = user ?? {}
  const { midwife, redFlags } = selectedAssessment ?? {}
  const [showMidwife, setShowMidwife] = useState(false)
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const update = useUpdateDoc()
  const midwifeName = useMemo(
    () => (midwife?.fname && midwife?.lname ? `${midwife.fname} ${midwife.lname}` : ''),
    [midwife],
  )

  const isMobile = useMemo(() => width < 1000, [width])
  const name = useMemo(
    () => `${fname ?? '[Missing first name]'} ${lname ?? '[Missing Last Name]'}`,
    [fname, lname],
  )

  return (
    <>
      <Stack
        borderBottom="1px solid #cdcdcd"
        divider={<StackDivider borderColor="blackAlpha.400" />}
        // align='center'
        direction="row"
        flexFlow={isMobile ? 'column' : 'row'}
        bg="#FFC7BC"
        spacing={isMobile ? 1 : 3}
        py={2}
        minH="60px"
        align={isMobile ? 'flex-start' : 'center'}
        px={4}
        // pb={0}
      >
        <HStack spacing={2} py={0.5}>
          <Flex>
            {appName === 'app' && snippetRef && selectedAssessment ? (
              <ProfileUrgentPopover />
            ) : null}
            {appName === 'providers-app' && practiceRef && selectedAssessment ? (
              <ProfileSortColorPopover />
            ) : null}
          </Flex>
          <Tooltip placement="bottom" bg="gray.500" hasArrow fontSize="md" label="Edit name">
            <Button
              onClick={() => setNameModalOpen(true)}
              variant="link"
              fontSize="md"
              fontFamily="Hero-New"
              py={2}
              fontWeight={500}
              color="gray.700">
              {name ?? 'No name entered'}
              {nickname ? ` (${nickname})` : ''}
            </Button>
          </Tooltip>
          {pronouns ? <PronounsView /> : null}
          <PartnerPopover />
          {/* <ReturningPatientIcon /> */}
        </HStack>
        <Stack
          flexFlow={['column', 'column', 'column', 'row']}
          w={isMobile ? '100%' : 'auto'}
          pl={1}
          spacing={isMobile ? 1 : 3}>
          <AssessmentSelect />
          <Flex gap={2} align="center">
            {selectedAssessment && appName === 'app' ? (
              <Flex align="center">
                <Button
                  fontWeight={400}
                  color="blackAlpha.700"
                  bg="whiteAlpha.800"
                  _hover={{ bg: 'white' }}
                  style={{ alignItems: 'center' }}
                  onClick={() => setShowMidwife(true)}
                  alignItems="flex-start"
                  display="flex"
                  p={2}
                  variant="unstyled">
                  <VStack align="flex-start" spacing={0}>
                    <Text
                      fontFamily="Comfortaa"
                      lineHeight={1}
                      fontWeight={600}
                      isTruncated
                      fontSize="md"
                      maxW="250px">
                      {midwifeName}
                    </Text>
                    <Text lineHeight={1} isTruncated fontSize="sm" maxW="250px">
                      {midwife?.name ?? 'No practice name'}
                    </Text>
                  </VStack>
                  {appName === 'app' && midwife?.redFlaggedReason ? (
                    <MidwifeRedFlagPopover redFlaggedReason={midwife?.redFlaggedReason} />
                  ) : null}
                </Button>
              </Flex>
            ) : null}
            {appName === 'app' && selectedAssessment && redFlags ? (
              <Box>
                <ProfileRedFlagPopover redFlags={redFlags} />
              </Box>
            ) : null}
          </Flex>
        </Stack>
        {selectedAssessment ? (
          <HStack w={isMobile ? '100%' : 'auto'} pr={isMobile ? 0 : 10} ml={isMobile ? 0 : 'auto'}>
            {isMobile ? (
              <Flex gap={2} align="center" ml="auto">
                <ProfileHeaderAlerts />
                <ReportDeliveryPopover mobileLayout={isMobile} />
              </Flex>
            ) : (
              <ProfileHeaderAlerts />
            )}
          </HStack>
        ) : null}
        <Flex justify="center" gap={2} align="center">
          {appName === 'providers-app' ? <AddPaymentPopover /> : null}
          {appName === 'providers-app' ? <UploadFilePopover /> : null}
          {isMobile ? null : <ReportDeliveryPopover mobileLayout={isMobile} />}
        </Flex>
      </Stack>
      {showMidwife ? (
        <DefaultModal
          overlayHeader
          size="4xl"
          contentProps={{ p: 0 }}
          isOpen={showMidwife}
          onClose={() => setShowMidwife(false)}
          render={() => <PatientMidwifeInfo />}
        />
      ) : null}
      <NameEditModal
        userType="patient"
        value={user}
        isOpen={nameModalOpen}
        onClose={() => setNameModalOpen(false)}
        onSubmit={v => update(patientRef, '', v)}
      />
    </>
  )
}
