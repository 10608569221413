import { adminAccess, superAdminAccess, userAccess } from '@hb/shared'
import {
  AdminsView,
  ContactView,
  getAdminAssessmentRedirectView,
  getAdminDashboardPaths,
  RedirectView,
  View,
} from '@hb/shared-frontend'
import { PracticeAssessmentsView } from './Views/Assessments'
import { ConsentFormsView } from './Views/ConsentForms'
import { PracticeInvoicesView } from './Views/Invoices'
import { PracticeInfoView } from './Views/Practice/PracticeInfoView'
import { SettingsView } from './Views/Settings/SettingsView'
import { SignInPage } from './Views/SignInPage'

const headerDashboardView: View = {
  name: 'Dashboard',
  path: getAdminDashboardPaths('providers-app'),
  // exact: true,
  component: PracticeAssessmentsView,
  access: adminAccess,
  onMenu: true,
}

const signInView: View = {
  name: 'Sign In',
  path: ['/', '/sign-in'],
  // exact: true,
  component: SignInPage,
}

const contactView: View = {
  name: 'Contact',
  path: '/contact',
  component: ContactView,
  onMenu: true,
}

const practiceInfoView: View = {
  name: 'Practice Info',
  path: ['/practice-info', '/practice-info/:displayedItem'],
  component: PracticeInfoView,
  access: adminAccess,
  onMenu: true,
  signIn: true,
}

const adminsView: View = {
  name: 'Admins',
  path: ['/admins', '/admins/:adminId', '/admins/:adminId/:displayedItem'],
  component: AdminsView,
  access: adminAccess,
  signIn: true,
  onMenu: true,
}

const consentFormsView: View = {
  name: 'Consent Forms',
  path: '/consent-forms',
  component: ConsentFormsView,
  access: superAdminAccess,
  onMenu: true,
}
const invoicesView: View = {
  name: 'Invoices',
  path: [
    '/super-admin/invoices',
    '/super-admin/invoices/:invoicesTab',
    '/super-admin/invoices/:invoicesTab/:invoiceId',
  ],
  access: superAdminAccess,
  onMenu: true,
  component: PracticeInvoicesView,
}

const settingsView: View = {
  name: 'Settings',
  path: '/settings',
  component: SettingsView,
  access: userAccess,
  onMenu: true,
}

const redirectView: View = {
  name: 'Redirect',
  path: '/*',
  component: RedirectView,
}

export const views: View[] = [
  signInView,
  getAdminAssessmentRedirectView('providers-app'),
  headerDashboardView,
  contactView,
  practiceInfoView,
  adminsView,
  consentFormsView,
  settingsView,
  invoicesView,
  redirectView,
]
