import { Box, Center } from '@chakra-ui/react'
import { PageContainer, SignInContent, useAuth } from '@hb/shared-frontend'
import React from 'react'
import { PracticeAssessmentsView } from './Assessments'

export const SignInPage = () => {
  const authUser = useAuth(s => s.authUser)
  if (authUser) {
    return <PracticeAssessmentsView />
  }
  return (
    <PageContainer>
      <Center w="100%">
        <Box w="100%" maxW="500px" bg="white">
          <SignInContent />
        </Box>
      </Center>
    </PageContainer>
  )
}
