import { Box, Center, CenterProps } from '@chakra-ui/react'
import {
  FileTemplateAttachment,
  getInlineFieldSize,
  InlineFilePlacement,
  InputTemplateAttachment,
  PIXEL_PDF_UNITS_SCALE,
  TemplateAttachment,
} from '@hb/shared'
import { motion, MotionProps } from 'framer-motion'
import React, { ForwardRefExoticComponent, PropsWithChildren, useCallback, useMemo } from 'react'
import { OrientationOverlay } from '../Gestures/OrientationOverlay'
import { TemplateFieldElementView } from '../RichText/Elements/TemplateFieldElementView'
import { useEditMode } from '../Templates/contexts/editMode'
import { useExternalPdfTemplateEditor } from '../Templates/ExternalPdf/context'
const MCenter = motion.create(
  Center as ForwardRefExoticComponent<CenterProps>,
) as ForwardRefExoticComponent<Omit<CenterProps, 'style'> & MotionProps>

interface ContainerProps {
  pageWidth: number
  pageHeight: number
}

const InlinePlacementEdit = ({
  children,
  attachment,
  selected,
  pageHeight,
  pageWidth,
}: PropsWithChildren<
  {
    attachment: TemplateAttachment<InlineFilePlacement>
    selected: boolean
  } & ContainerProps
>) => {
  const { id: attachmentId, placement } = attachment
  const { scale } = placement.orientation
  const { onAttachmentSelect, updateAttachment, deleteAttachment } = useExternalPdfTemplateEditor()
  const size = useMemo(() => getInlineFieldSize(attachment), [attachment])
  const bounds = useMemo(() => {
    const minX = size[0] / 2
    const minY = size[1] / 2
    const maxX = pageWidth * PIXEL_PDF_UNITS_SCALE - size[0] / 2
    const maxY = pageHeight * PIXEL_PDF_UNITS_SCALE - size[1] / 2
    return { minX, minY, maxX, maxY }
  }, [pageHeight, pageWidth, size])
  const onMove = useCallback(
    (pos: [number, number]) => {
      updateAttachment(attachmentId, {
        ...attachment,
        placement: {
          ...attachment.placement,
          orientation: {
            ...attachment.placement.orientation,
            position: [
              Math.min(Math.max(pos[0] * PIXEL_PDF_UNITS_SCALE, bounds.minX), bounds.maxX),
              Math.min(Math.max(pos[1] * PIXEL_PDF_UNITS_SCALE, bounds.minY), bounds.maxY),
            ],
          },
        },
      })
    },
    [attachment, attachmentId, updateAttachment, bounds],
  )

  const onResize = useCallback(
    (scale: [number, number]) => {
      updateAttachment(attachmentId, {
        ...attachment,
        placement: {
          ...attachment.placement,
          orientation: {
            ...attachment.placement.orientation,
            scale,
          },
        },
      })
    },
    [attachment, attachmentId, updateAttachment],
  )

  const position = useMemo<[number, number]>(() => {
    const { position } = placement.orientation
    return [position[0] / PIXEL_PDF_UNITS_SCALE, position[1] / PIXEL_PDF_UNITS_SCALE]
  }, [placement])

  return (
    <Box
      borderRadius={4}
      onClick={e => {
        e.stopPropagation()
        onAttachmentSelect(attachmentId)
      }}
      cursor="pointer"
      position="absolute"
      transition="background-color 300ms"
      w="100%"
      h="100%">
      <Box w="100%" h="100%" position="relative">
        <Center userSelect="none" position="relative">
          {children}
          <Box
            zIndex={2}
            pointerEvents="auto"
            position="absolute"
            top={0}
            left={0}
            w="100%"
            h="100%"
          />
        </Center>
        {selected ? (
          <OrientationOverlay
            zoom={scale[0]}
            position={position}
            constrainAspectRatio
            scale={scale}
            onMove={onMove}
            onResize={onResize}
            onDelete={() => deleteAttachment(attachmentId)}
          />
        ) : null}
      </Box>
    </Box>
  )
}

const InlinePlacementContainer = ({
  children,
  pageHeight,
  attachment,
  pageWidth,
  selected,
}: PropsWithChildren<
  {
    attachment: TemplateAttachment<InlineFilePlacement>
    selected: boolean
  } & ContainerProps
>) => {
  const editMode = useEditMode()
  const { placement } = attachment
  const { orientation } = placement
  const { position, scale } = orientation
  const [scaleX, scaleY] = scale
  const { x, y, width, height } = useMemo(() => {
    const [baseW, baseH] = getInlineFieldSize(attachment)
    const w = baseW / PIXEL_PDF_UNITS_SCALE
    const h = baseH / PIXEL_PDF_UNITS_SCALE
    return {
      width: w,
      height: h,
      x: position[0] / PIXEL_PDF_UNITS_SCALE,
      y: position[1] / PIXEL_PDF_UNITS_SCALE,
    }
  }, [position, attachment])
  return (
    <MCenter
      position="absolute"
      initial={{
        opacity: 0,
        scaleX: scaleX * 0.9,
        scaleY: scaleY * 0.9,
      }}
      style={{
        transformOrigin: '0 0',
      }}
      key={attachment.id}
      animate={{ opacity: 1, scaleX, scaleY }}
      exit={{ opacity: 0, scaleX: scaleX * 0.9, scaleY: scaleY * 0.9 }}
      transformTemplate={({ scaleX, scaleY }) =>
        `scale(${scaleX}, ${scaleY}) translate(-50%, -50%)`
      }
      w={`${width}px`}
      top={`${y}px`}
      left={`${x}px`}
      h={`${height}px`}>
      {editMode === 'template' ? (
        <InlinePlacementEdit
          pageHeight={pageHeight}
          pageWidth={pageWidth}
          attachment={attachment}
          selected={selected}>
          {children}
        </InlinePlacementEdit>
      ) : (
        children
      )}
    </MCenter>
  )
}

const AttachmentPlacementContainer = ({
  attachment,
  pageHeight,
  pageWidth,
  children,
}: PropsWithChildren<{ attachment: TemplateAttachment } & ContainerProps>) => {
  const { selectedAttachmentId } = useExternalPdfTemplateEditor()
  const { id: attachmentId, placement } = attachment
  const selected = useMemo(
    () => selectedAttachmentId === attachmentId,
    [selectedAttachmentId, attachmentId],
  )
  if (placement.type === 'inline')
    return (
      <InlinePlacementContainer
        selected={selected}
        attachment={attachment as TemplateAttachment<InlineFilePlacement>}
        pageHeight={pageHeight}
        pageWidth={pageWidth}>
        {children}
      </InlinePlacementContainer>
    )

  return (
    <Center width={`${pageWidth}px`} height={`${pageHeight}px`}>
      {children}
    </Center>
  )
}
const InputAttachmentView = ({ attachment }: { attachment: InputTemplateAttachment }) => {
  const { selectedAttachmentId, onAttachmentSelect } = useExternalPdfTemplateEditor()
  const { id: attachmentId } = attachment
  const selected = useMemo(
    () => selectedAttachmentId === attachmentId,
    [selectedAttachmentId, attachmentId],
  )
  return (
    <Box bg="white">
      <TemplateFieldElementView
        onSelect={() => onAttachmentSelect(attachmentId)}
        field={attachment.field}
        isSelected={selected}
        mode="View"
        name={attachmentId}
      />
    </Box>
  )
}

const FileAttachmentView = ({ attachment }: { attachment: FileTemplateAttachment }) => (
  <Box>{attachment.file.name}</Box>
)

export const AttachmentView = ({
  attachment,
  ...containerProps
}: {
  attachment: TemplateAttachment
  pageWidth: number
  pageHeight: number
}) => (
  <AttachmentPlacementContainer {...containerProps} attachment={attachment}>
    {attachment.type === 'input' ? (
      <InputAttachmentView attachment={attachment} />
    ) : (
      <FileAttachmentView attachment={attachment} />
    )}
  </AttachmentPlacementContainer>
)
