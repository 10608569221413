import { adminRoles } from '../constants'
import {
  AdminUserData,
  AppName,
  BaseInsuranceCoverage,
  FieldMapValue,
  InsuranceCoverage,
  InsuranceCoverageId,
  InsuranceProvider,
  NextAction,
  PatientNextActions,
  PracticeWithAdmin,
  UserGroup,
  UserGroupLabel,
  UserRole,
  UserRoleItem,
  WithId,
  WithMetadata,
} from '../types'
import { PopulatedUser } from '../types/app/populated'
import { PopulatedInsurancePlans, User, UserInsurancePlans } from '../types/users/user'
import { parseNextActionDate } from './data'

export const getInitials = (name: string) => {
  const names = name.split(' ')
  return names.reduce((acc, curr) => {
    if (curr[0]) {
      return acc + curr[0].toUpperCase()
    }
    return acc
  }, '')
}

export const getFullName = (
  user?: User | PracticeWithAdmin | FieldMapValue | null,
  ignoreMiddle?: boolean,
) => {
  let name = ''
  const { fname, lname } = user ?? {}
  if (fname && typeof fname === 'string') name += fname
  if (!ignoreMiddle && (user as User)?.middleName) name += ` ${(user as User).middleName}`
  if (lname) name = `${name ? `${name} ` : ''}${lname}`
  return name ?? user?.name ?? ''
}

export const getNameAndInitials = (
  appName: AppName,
  user?: UserRoleItem | null,
  withRole?: boolean,
) => {
  if (!user) return { name: '', initials: '' }
  const group = getUserGroup(user)
  const fullName = getFullName(user)
  const __name = `${fullName}${group && withRole ? ` | ${appName === 'providers-app' && adminRoles.includes(user.role) ? 'Practice ' : ''}${userGroupLabels[group]}` : ''}`
  const _initials = getInitials(fullName)
  return { name: __name, initials: _initials }
}

export const getReverseName = (user?: any | null) => {
  let name = ''
  const { lname, fname } = user ?? {}
  if (fname && typeof fname === 'string') name += fname
  if (lname) name = `${lname}, ${name ? name : ''}`
  if (name) return name
  if ((user as User)?.name) {
    return (user as User)?.name?.split(' ').reverse().join(', ')
  }
  return user?.email
}

const populateInsurancePlan = (
  id: InsuranceCoverageId,
  coverage: WithMetadata<BaseInsuranceCoverage>,
  insurers: Record<string, InsuranceProvider>,
): InsuranceCoverage => {
  const insurer = coverage.insuranceProviderId
    ? (insurers[coverage.insuranceProviderId] ?? null)
    : null
  const plan = coverage.planName
    ? (insurer?.plans?.find(p => p.name === coverage.planName) ?? null)
    : null
  return {
    ...coverage,
    id,
    plan,
    insuranceProvider: insurer,
  }
}

export const populateInsurancePlans = (
  insurancePlans: UserInsurancePlans,
  insurers: Record<string, InsuranceProvider>,
): PopulatedInsurancePlans => {
  const { primary, secondary, additional = {}, requests = {} } = insurancePlans
  return {
    primary: primary ? populateInsurancePlan('primary', primary, insurers) : null,
    secondary: secondary ? populateInsurancePlan('secondary', secondary, insurers) : null,
    additional: Object.entries(additional ?? {}).reduce<PopulatedInsurancePlans['additional']>(
      (acc, [key, plan]) => ({
        ...acc,
        [key]: populateInsurancePlan(`additional.${key}`, plan, insurers),
      }),
      {},
    ),
    requests,
  }
}

export const populateUser = (
  usersItem: WithId<User>,
  adminItem: AdminUserData | null,
  insurers: Record<string, InsuranceProvider>,
  isInvite: boolean,
): PopulatedUser => {
  const insurancePlans = populateInsurancePlans(usersItem.insurancePlans ?? {}, insurers)

  return {
    ...usersItem,
    ...adminItem,
    insurancePlans,
    insuranceProvider: insurancePlans?.primary?.insuranceProvider ?? null,
    isInvite,
    reverseName: getReverseName(usersItem),
  }
}

export const getIsAdmin = (user: UserRoleItem) => adminRoles.includes(user.role)
export const getIsPracticeAdmin = (user: UserRoleItem) =>
  user.practiceAccess &&
  Object.keys(user.practiceAccess).length > 0 &&
  Object.values(user.practiceAccess).some(role => adminRoles.includes(role))

export const getUserGroup = (user?: UserRoleItem | null): UserGroup => {
  if (!user) return 'patient'
  if (getIsAdmin(user)) return 'admin'
  if (getIsPracticeAdmin(user)) return 'practice'
  return 'patient'
}

export const getPatientUserGroup = (appName: AppName, uid: string, patientId: string) => {
  if (uid === patientId) return 'patient'
  return appName === 'providers-app' ? 'practice' : 'admin'
}

export const userGroupLabels: Record<UserGroup, UserGroupLabel> = {
  admin: 'Admin',
  practice: 'Provider',
  patient: 'Patient',
}

export const getAppRole = (
  appName: AppName,
  uid: string | null,
  claims: Record<string, any> | null,
  selectedPracticeId: string | null,
): UserRole | null => {
  if (appName === 'providers-app') {
    if (!selectedPracticeId) return uid ? 'user' : null
    return claims?.practiceAccess[selectedPracticeId] || (uid ? 'user' : null)
  }
  if (claims?.superAdmin) return 'super-admin'
  if (claims?.admin) return 'admin'
  return uid ? 'user' : null
}

export const getClosestNextAction = (nextActions: PatientNextActions) =>
  [
    nextActions?.primaryCoverage,
    nextActions?.secondaryCoverage,
    ...Object.values(nextActions?.additionalPlans ?? {}),
  ].reduce<NextAction | null>((acc, curr) => {
    if (!acc) return curr ?? null
    if (!curr) return acc
    const date = parseNextActionDate(curr.text)
    if (!date) return acc
    if (date < parseNextActionDate(acc.text)) return curr ?? null
    return acc
  }, null)
